import React from "react";

import { Container } from "@mantine/core";

function Footer() {
  return <Container></Container>;
}

export default Footer;

//   m={0}
//   mt={30}
//   p={0}
//   sx={{
//     width: "100%",
//     maxWidth: 400,
//     height: 60,
//   }}
// >
//   <Button.Group w="100%">
//     <Button variant="default" w="50%" h={60}>
//       Food Image
//     </Button>
//     <Button variant="default" w="50%" h={60}>
//       Record
//     </Button>
//   </Button.Group>

export enum MealType {
  Fasting = "Fasting",
  Breakfast = "Breakfast",
  Lunch = "Lunch",
  Dinner = "Dinner",
  Snack_MORNING = "Snack(morning)",
  Snack_AFTERNOON = "Snack(afternoon)",
  Snack_NIGHT = "Snack(night)",
}

export enum MealBasicType {
  Fasting = "Fasting",
  Breakfast = "Breakfast",
  Lunch = "Lunch",
  Dinner = "Dinner",
}

export enum DailyFormType {
  Image = "Image",
  BloodSugar = "BloodSugar",
}

export enum DisplayType {
  MONTH = "Monthly",
  DAY = "Daily",
  GRAPH = "Graph",
}

export enum AuthProvider {
  GOGGLE = "google",
}
